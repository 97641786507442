@use "sass:map";

@import './_variables';

.text-decoration-none {
  text-decoration: none !important;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.p-0 {
  padding: 0px !important;
}
.mh-100 {
  max-height: 100% !important;
}
.disabled-state {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.events-none {
  pointer-events: none !important;
}


/* CSS for Full Calendar */
.fc-scrollgrid-sync-inner {
  text-align: end;
  color: $darkGrey;
  text-decoration: none;
  opacity: 1;
  pointer-events: auto;
  font-weight: map.get($fontWeights, "hairline");
  cursor: pointer;
  font-size: 12px;
}

.fc .fc-timeGridWeek-view .fc-scrollgrid-sync-inner {
  text-align: center;
  font-size: 12px;
}

.fc-v-event {
  display: block;
  border: 0.1px solid $teal;
  border: 0.1px solid var(--fc-event-border-color, $teal);
  background-color: transparent;
}

/* focus click on toolbar */
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}
/* CSS for tool bar button group */
.fc .fc-button-group {
  height: 40px;
  width: 255px;
  border-radius: 10px;
  background-color: $white;
}
@media (max-width: 992px) { 
  .fc .fc-button-group {
    width: 100%;
  }

}
/* CSS for toolbar chunk */
.fc .fc-button-primary {
  color: $lightBlack;
  background-color: $white;
  background-color: var(--fc-button-bg-color, $white);
  border: 0;
  text-transform: uppercase;
  font-family: map.get($fonts, semibold);
  font-style: normal;
  font-weight: map.get($fontWeights, medium);
  font-size: map.get($fontSizes, small);
  line-height: map.get($lineHeight, none);
  text-align: center;
  border-radius: map.get($radii, medium);
}

/* CSS for toolbar chunk on hover*/
.fc .fc-button-primary:hover {
  color: $black;
  color: var(--fc-button-text-color, $black);
  border: 0;
  background-color: $white;
  text-transform: uppercase;
  border-radius: 10px;
}
.fc-button-active:hover {
  background-color: $orange !important;
}
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: $black;
  color: var(--fc-button-text-color, $black);
  background-color: $orange;
  background-color: var(--fc-button-active-bg-color, $orange);
  border: 0;
  text-transform: uppercase;
  box-shadow: none;
  border-radius: 10px;
}

.fc .fc-timegrid-slot-label-cushion {
  width: 40px;
  word-break: break-all;
  display: block;
  white-space: break-spaces;
  font-size: map.get($fontSizes, xs);
  font-weight: 400;
  line-height: map.get($lineHeight, none);
  padding: 0;
  color: $darkGrey;
  text-align: center;
}

.fc .fc-timegrid-slot {
  height: 50px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.875rem;
}

.fc .fc-dayGridMonth-view .fc-scrollgrid {
  width: fit-content;
  display: block;
}
.fc .fc-scrollgrid {
  box-shadow: 0px 7px 64px rgb(0 0 0 / 7%);
  background-color: $white;
  border-radius: map.get($radii, "large");
  cursor: auto;
  opacity: 1;
  pointer-events: auto;
  border: 0;
}
.fc .fc-timegrid-divider{
  padding: 0px !important;
}
.fc .fc-timegrid-axis-frame {
  font-family: map.get($fonts, regular);
}
.fc-dayGridMonth-view .fc-daygrid-day-events {
  display: flex;
  max-width: 150px;
  overflow: hidden;
  align-items: center;
  text-align: right;
  justify-content: center;
  float:right;
  min-width: 150px;
}

@media (max-width: 768px) {
  .fc-dayGridMonth-view .fc-daygrid-day-events {
    max-width: 85px !important;
  }
}


.fc-timeGridDay-view .fc-timegrid-slot.fc-timegrid-slot-lane {
  cursor: pointer;
}
/* Day view show plus icon */
.fc .fc-timeGridDay-view .fc-timegrid-slot-lane:hover:before {
  width: 30px !important;
  height: 50px;
  border-radius: 5px;
  background: url('../assets/Icons/CalendarAddIcon.svg');
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.fc .fc-timeGridWeek-view .fc-timegrid-slot,
.fc .fc-timeGridDay-view .fc-timegrid-slot {
  height: 50px;
  font-family: map.get($fonts, regular);
  font-style: normal;
  font-weight: normal;
  font-size: map.get($fontSizes, xs);
  line-height: map.get($lineHeight, none);
  align-items: center;
  text-align: center;
  cursor: pointer;
}
/* remove bg color for today in day view */
.fc .fc-timeGridDay-view .fc-timegrid-slot {
  background-color: $white;
}
/* month view title bar */
.fc-theme-standard .fc-dayGridMonth-view thead td:first-child,
.fc-theme-standard .fc-col-header-cell {
  height: 30px;
  border: 0.1px solid transparent !important;
  background: transparent;
}

.fc-theme-standard .fc-dayGridMonth-view td {
  height: 100px;
  border-top: 0.1px solid $borderBlue !important;
  border-right: 0.1px solid var(--fc-border-color, $borderBlue) !important;
  border: 0px solid var(--fc-border-color, $lightWhite);
  background-color: $white;
}
.fc-theme-standard .fc-dayGridMonth-view td:last-child {
  border-right: 0 !important;
}

.fc .fc-dayGridMonth-view .fc-timegrid-slot-label-cushion {
  text-transform: uppercase;
}

.fc-dayGridMonth-view .fc-v-event .fc-event-main {
  color: $black;
  color: var(--fc-today-bg-color, $black);
}

.fc-timegrid-col-events .fc-event-start .fc-event-main{
  width: 100%;
}

.fc .fc-dayGridMonth-view .fc-daygrid-event-harness {
  margin-top: 0 !important;
  display: inline-flex !important;
  width: 100% !important;
}

.fc .fc-dayGridMonth-view .fc-daygrid-more-link {
  margin-left: 20px;
  color: $lightBlack;
  font-style: normal;
  font-family: map.get($fonts, semibold);
  font-weight: map.get($fontWeights, medium);
  font-size: map.get($fontSizes, regular);
  line-height: map.get($lineHeight, loose);
}

/* CSS for Title of the month */
.fc .fc-dayGridMonth-view .fc-toolbar-title {
  font-family: map.get($fonts, semibold);
  font-style: normal;
  font-weight: map.get($fontWeights, medium);
  font-size: map.get($fontSizes, xxl);
  line-height: map.get($lineHeight, more);
  color: $lightBlack;
}

/* Common CSS for column headers */
.fc-col-header-cell-cushion {
  font-family: map.get($fonts, regular);
  font-style: normal;
  padding: 4px 8px !important;
  align-items: center;
  display: flex !important;
  justify-content: center;
}
.fc .fc-daygrid-day-bottom {
  padding: 0 !important;
}
.fc .fc-daygrid-event{
  margin: 0 !important;
}

.fc .fc-button .fc-icon {
  color: $green;
  font-size: 1rem;
  font-weight: bold;
}

/* Month view CSS for day name - Mon */
.fc-dayGridMonth-view .fc-col-header-cell-cushion,
.fc-week-dayname {
  text-transform: capitalize;
  font-weight: map.get($fontWeights, normal);
  line-height: map.get($lineHeight, none);
  font-size: map.get($fontSizes, small);
  color: $darkGrey;
}
.fc-week-dayname {
  padding-right: 5px;
}

@media (max-width: 570px) {
  .fc-week-dayname {
    padding-right: 0;
  }
}

/* Week view top empty space hidden */
.fc-timeGridWeek-view .fc-col-header {
  colgroup {
    display: none;
  }
  .fc-timegrid-axis {
    width: 41px;
  }
}

option[value=""][disabled] {
  display: none;
}

/* Day view hide cushion header */
.fc-timeGridDay-view .fc-col-header-cell-cushion {
  display: none !important;
}

/* CSS for day number in month view */
.fc .fc-dayGridMonth-view .fc-daygrid-day-number,
.fc-week-daynumber {
  font-family: map.get($fonts, semibold);
  font-style: normal;
  font-weight: map.get($fontWeights, medium);
  font-size: map.get($fontSizes, medium);
  line-height: map.get($lineHeight, loose);
  text-align: right;
  color: $lightBlack;
}
.fc .fc-dayGridMonth-view .fc-daygrid-day-number {
  display: flex;
  align-items: center;
  padding-right: 16px;
}

/* CSS for today background color month view */
.fc .fc-dayGridMonth-view .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent !important;
}
.fc .fc-timegrid-bg-harness{
  cursor: pointer;
}
/* CSS for tool bar chunk */
.fc-toolbar-chunk {
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.3));
  padding-right: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: auto;
}
/* toolbar prev and next button */
.fc-prev-button,
.fc-next-button {
  height: 40px;
  border-radius: 10px;
}

/* Week view basics */
.fc-theme-standard .fc-timeGridWeek-view td,
.fc-theme-standard .fc-timeGridWeek-view th {
  border: 0.1px solid rgb(202, 221, 253, 0.4) !important;
}

/* Day view basics */
.fc-theme-standard .fc-timeGridDay-view td,
.fc-theme-standard .fc-timeGridDay-view th {
  width: fit-content !important;
  border: 0.1px solid rgb(202, 221, 253, 0.4) !important;
}

/* Title of the day, week, month */
.fc .fc-toolbar-title {
  font-family: map.get($fonts, semibold);
  font-style: normal;
  font-weight: map.get($fontWeights, medium);
  line-height: map.get($lineHeight, more);
  font-size: 24px;
}

.fc-timeGridWeek-view .fc-timegrid-event-harness:hover {
  overflow: visible;
  z-index: 7 !important;
}
/* Event harness background color change */
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
  box-shadow: none !important;
  border: none !important;
}
/* event harness on hover in month view */
.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
  background: none;
}
/*
bg color for slot
*/
.fc .fc-bg-event {
  background-color: $orange;
  opacity: 0.3 !important;
}
.fc-timegrid-col-events .fc-timegrid-event-harness-inset {
  overflow: hidden;
}

.fc-timegrid-col-events .fc-timegrid-event-harness-inset:hover{
  z-index: 7 !important;
  overflow: visible;
}

/* Title of the day, week, month */
.fc .fc-toolbar-title:first-letter {
  text-transform: capitalize;
  font-family: map.get($fonts, semibold);
  font-style: normal;
  font-weight: map.get($fontWeights, medium);
  font-size: map.get($fontWeights, xl);
  line-height: map.get($lineHeight, more);
}

thead tr:first-child {
  th {
    div {
      overflow: hidden !important;
    }
  }
}

tbody tr:first-child {
  td {
    div {
      .fc-scroller{
        background-color: rgb(2, 86, 82, 0.05);
        overflow: hidden !important;
      }

    }
  }
}

.fc-addAvailable-button {
  background-color: transparent !important;
}
/* Responsive design - mobile */
@media (max-width: 992px) {

  .fc-addAvailable-button {
    background-color: $green !important;
    border-radius: 50% !important;
    width: 40px;
    height: 35px;
  }
  .fc-theme-standard .fc-dayGridMonth-view td, .fc-theme-standard .fc-dayGridMonth-view th {
    height: auto;
    width: 100px;
    padding-bottom: 4px;
  }
.fc-header-toolbar {
    display: contents !important;
}
.fc-header-toolbar .fc-toolbar-chunk:nth-of-type(odd) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.fc-view {
  overflow-x: scroll !important;
  padding-top: 20px;
}
.fc-dayGridMonth-view .fc-scrollgrid {
  display: block;
}
.fc .fc-dayGridMonth-view .fc-daygrid-more-link {
  margin-left: 20px;
}
.fc-timeGridDay-view .fc-timegrid-event-harness:hover {
  overflow-y: visible;
  overflow-x: clip;
  z-index: inherit !important;
  width: 200px;
}
.fc-timeGridWeek-view .fc-timegrid-axis-frame {
  min-width: 41px;
}
.fc-timeGridWeek-view .fc-scrollgrid {
  table-layout: auto !important;
  overflow: auto !important;
}
}

.react-calendar__navigation {
  background: transparent;
}

.react-calendar {
  border: none !important;
  width: 340px !important;
}

.react-calendar__tile--active {
  background: $lightBlue !important;
  color: $black;
  cursor: pointer;
}

.react-calendar__tile--now {
  background: none;
}

.react-calendar__month-view__days__day--weekend {
  color: $black;
}

.react-calendar__month-view__days__day--weekend:disabled {
  color: rgba(16, 16, 16, 0.3) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.is_selected {
  border: 3px solid $green;
  border-radius: 16px;
}
.border-line {
  border-top: 1px solid $lightGrey;
  opacity: 0.2 !important;
  padding-top: 30px;
}
.show-fc {
  visibility: visible;
}
.hide-fc {
  visibility: hidden;
}
.navItem .nav-image {
  display: inline;
}
.navItem:hover .nav-image {
  display: none;
}
.navItem .nav-img {
  display: none;
}
.navItem:hover .nav-img {
  display: inline;
}
.sc-giYglK .available-btn{
  flex-direction: column;
  border-radius: 25px;
  background: $green;
  padding: 3px 6px;
  font-size: 0.6rem;
  margin-top: 4px !important;
}
.available-btn{
  flex-direction: column;
  border-radius: 10px;
  background: $orange;
  padding: 6px 10px;
  font-size: 0.6rem;
}

.available-full-day-btn{
  background: $green !important;
}

.available-btn-dark{
  background: $darkGrey !important;
}
.available-btn-day{
  width: 10%;
  float: right;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  background: $green;
  padding: 3px 6px;
  font-size: 0.7rem;
  margin-top: 4px !important;
  margin-right: 3px;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  height: 100%;
  padding-top: 4px;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events{
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0 !important;
}

 .fc-dayGridMonth-view .fc-daygrid-day-events{
  align-items:start !important;
  float: left !important;
}

.fc-now-indicator.fc-now-indicator-line {
  border-color: #ef6c00 !important;
  background-color: #ef6c00;
  height: 2px;
  margin: 0;
  border: none;
}

.fc-now-indicator.fc-now-indicator-line::before {
  background-color: #ef6c00;
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-top: -6px;
  margin-left: -7px;
}

.fc-past {
  background: #a0a096;
}

.fc-h-event {
  background-color: transparent !important;
  border-color: transparent !important;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 !important;
}
.fc-timegrid-bg-harness .fc-event-past{
  opacity:1;
}
.fc-timeGridWeek-view .fc-day-past .fc-week-dayname{
  color: #a0a096;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}

.fc-timeGridWeek-view .fc-day-past .fc-week-daynumber{
  color: #a0a096;
}
.fc-timeGridWeek-view .fc-day-today {
    background-color: rgba(173,207,255,0.3) !important;
}
.fc-timeGridWeek-view .available-btn-day{
  width: 60%;
}
.fc-timegrid-col-events .fc-event-start{
  border-color: rgb(253, 194, 130, 0.3) !important;
  cursor: pointer;
}

.fc .fc-scroller {
  overflow: hidden;
}

.fc-myCustomButton-button {
  background-color: transparent !important;
}
.modal-dialog .react-datepicker__input-container {
  width: 120% !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display:none;
}
.order-model{
  border-bottom: 1px solid $green;
  color: $green;
}
.break-btn{
  border-radius: 10px !important;
  padding: 4px !important;
}

.fc-theme-standard .fc-timeGridDay-view td, .fc-theme-standard .fc-timeGridDay-view th{
  width: auto !important;
  overflow: hidden;
}
.fc-dayGridMonth-view .fc-daygrid-day-top {
  max-height: 30px;
}
.react-calendar__tile:disabled{
  background-color: transparent;
}
.react-calendar__month-view__days__day--neighboringMonth{
  color:rgba(16, 16, 16, 0.3);
}
.react-calendar__month-view__days__day {
  border-radius: 10px !important;

  &:focus {
    outline: none;
    border: none;
  }
}
.fc-scrollgrid-section .fc-timegrid-event {
  pointer-events: none;
}

.react-calendar__tile--range {
  border-radius: 0 !important;
}

.react-calendar__tile--rangeStart {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.react-calendar__tile--rangeEnd {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.react-calendar__tile--hover {
  background: $lightBlue !important;
}
