$orange: #FDC282;
$darkGrey: #646868;
$teal: #2c8a6b;
$white: #FFFFFF;
$lightBlue: #CADDFD;
$black: #000000;
$lightBlack: #1a2424;
$darkGrey: #646868;
$green: #025652;
$lightWhite: #DDDDDD;
$mediumWhite: #F9FAFE;
$lightGrey: #B6BABD;
$borderBlue: rgb(202,221,253,0.4);

$radii: (
  none: 0,
  small: 2px,
  regular: 5px,
  medium: 10px,
  large: 20px,
);

$fonts: (
  regular: 'Roboto',
  semibold: 'Rubik-Medium',
);

$fontWeights: (
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
  body: 400,
  heading: 700,
);

$fontSizes: (
  xs: 12px,
  small: 14px,
  regular: 16px,
  medium: 18px,
  large: 20px,
  xl: 24px,
  xxl: 26px,
  header: 36px,
);

$lineHeight: (
  none: 1,
  tight: 1.25,
  normal: 'normal',
  relaxed: 1.625,
  loose: 2,
  body: 1.625,
  heading: 1.25,
  more: 1.75,
);